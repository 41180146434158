var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{},[_c('span',{staticClass:"me-3"},[_vm._v("Contact Us Messages")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.contactUsList,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.client_username",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer","text-decoration":"none"},attrs:{"href":'/savings/contact_us/reply/'+item.id}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.client_username))]),_c('small',[_vm._v("Contact: "+_vm._s(item.phone_number))])])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject)+" ")]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [(item.read)?_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"primary"}},[_vm._v(" Read ")]):_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"error"}},[_vm._v(" UnRead ")])]}},{key:"item.replied",fn:function(ref){
var item = ref.item;
return [(item.read)?_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"success"}},[_vm._v(" Replied ")]):_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":"primary"}},[_vm._v(" Unreplied ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }